import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import Telefon from "../../assets/img/telefonBlue.svg";
import TeamSmall from "../../assets/img/teamSmall.png";
import { useMediaQuery } from "react-responsive";


const AvailabilityPanelInner = styled.div`
  /* background: #FFEABF; */
  /* background: #fff1b8; */
  background: #fff1c7;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 0;
  position: sticky;
  top: 0;
  z-index: 50000;

  .greenDot {
    background: #49B25D;
    height: 10px;
    width: 10px;
    border-radius: 100%;
    animation: pulse 2s infinite;
    display: inline-block;
    vertical-align: middle;
  }

  @keyframes pulse {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0.25;
    }

    100% {
      opacity: 1;
    }
  }

  span {
    font-family: Visuelt-Medium;
    font-size: 18px;
    color: #243A58;
    margin-left: 8px;

    @media (max-width: 800px) {
      margin-right: 20px;
    }

    @media (max-width: 470px) {
      font-family: Visuelt-Regular;
      font-size: 16px;
      color: #243A58;
    }

    @media (max-width: 430px) {
      margin-right: 10px;
    }

    @media (max-width: 350px) {
      font-family: Visuelt-Regular;
      font-size: 14px;
    }
  }

  p {
    font-family: Visuelt-Regular;
    font-size: 16px;
    margin-left: 12px;
    margin-right: 20px;
    color: #243A58;

    @media (max-width: 800px) {
      display: none;
    }
  }

  .team {
    height: 32px;
  }

  a {
    margin-left: 20px;
    display: flex;
    align-items: center;

    @media (max-width: 430px) {
      margin-left: 10px;
    }

    img {
      margin-right: 10px;
    }

    span {
      font-family: Visuelt-Bold;
      font-size: 16px;
      color: #243A58;
      margin: 0;
    }
  }
`;

const AvailabilityPanel = () => {

  const isUnder430 = useMediaQuery({
    query: '(max-width: 430px)'
  });

  return (
    <AvailabilityPanelInner>
      <div className="greenDot"></div>
      <span>{isUnder430 ? "K dispozici 24/7" : "Jsme k dispozici 24/7"}</span>
      <p>Náš tým na pomocné lince vám se vším pomůže</p>
      <img className="team" src={TeamSmall} alt="Tým pohřební služby Goodbye.cz" />
      <a href="tel:315558136">
        <img src={Telefon} alt="" />
        <span>315 558 136</span>
      </a>
    </AvailabilityPanelInner>
  );
}

export default AvailabilityPanel;


