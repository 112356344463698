import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

import Logo from "../assets/img/logo_new.svg";
//import Heureka from "../assets/img/heureka.png";
import Telefon from "../assets/img/telefon.svg";
import SignOutImg from "../assets/img/signOut.svg";
import { useMediaQuery } from "react-responsive";
import { useCookies } from "react-cookie";
import { navigate } from "@reach/router";




const HeaderInner = styled.header`
  width: 100%;

  .hlavniMenu {
    padding: 20px 0;
    height: 24px;
    border-bottom: 1px solid #DEDEDE !important;
    position: relative;

    .headerWrapper {
      position: relative;
      max-width: calc(1150px + 50px);
      width: calc(100% - 50px);
      margin: 0 auto;

      max-width: ${props => props.isLoggedIn && "calc(1200px + 50px)"};

      @media (max-width: 850px) {
        width: calc(100%);
      }

      .logo {
        position: absolute;
        top: 0px;
        left: 25px;

        img {
          height: 28px;
        }
      }
    }

    .telefon {
      display: inline-block;
      position: absolute;
      top: 3.2px;
      right: 25px;
      width: fit-content;

      @media (max-width: 600px) {

      }

      img {
        height: 22px;
        vertical-align: middle;
        margin-right: 15px;
        position: relative;
        top: -1px;
        left: 0px;

        @media (max-width: 600px) {
          height: 18px;
          left: -0px;
          top: -2px;
        }

        @media (max-width: 330px) {
          display: none;
        }
      }

      a {
        vertical-align: middle;
        font-family: Visuelt-Bold, Helvetica;
        font-size: 16px;
        color: #000000;
        word-wrap: none;

        span {
          font-family: Visuelt-Regular, Helvetica;
          font-size: 16px;
          color: #000000;

          @media (max-width: 600px) {
            display: none;
          }
        }
      }
    }
  }

  .signOut {
    background: #E2E4E7;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 0;
    border-radius: 3px;
    padding: 9px 15px;
    position: absolute;
    top: -7px;
    right: 25px;

    &:hover {
      background: #C5CDD9;
      cursor: pointer;
    }

    img {
      margin-right: 12px;
    }

    span {
      font-family: Visuelt-Regular;
      font-size: 16px;
      color: #000000;
      text-align: right;
    }
  }
`;




const SimpleHeader = ({ otevritMenu, showPhone, isLoggedIn, logOut }) => {

  const signOut = () => {
    logOut();
    navigate("/");
  }


  const isPlus400 = useMediaQuery({
    query: '(min-width: 400px)'
  });

  return (
    <HeaderInner isLoggedIn={isLoggedIn}>
      <div className="hlavniMenu">
        <div className="headerWrapper">
          <Link className="logo" to={isLoggedIn ? "/nabidka/prehled/" : "/"}>
            <img loading="eager" src={Logo} alt="Logo Goodbye" />
          </Link>
          
          {(showPhone) &&
            <div className="telefon">
              <img src={Telefon} alt="Pomocná linka" />
              <a href="tel:315558136">315 558 136<span> - Pomocná linka</span></a>
            </div>
          }

          {isLoggedIn && 
            <button onClick={() => signOut()} className="signOut">
              <img src={SignOutImg} alt="Uložit a odhlásit se" />
              
              {!isPlus400 ?
                <span>Odhlásit se</span>
                :
                <span>Uložit a odhlásit se</span>
              }
            </button>
          }
        </div>
      </div>
    </HeaderInner>
  )
}


export default SimpleHeader;
