import React, { useState } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { useLocation } from '@reach/router';

import arrowImg from "../assets/img/arrow.svg";
import loginImg from "../assets/img/user.svg";
import Logo from "../assets/img/logo_new.svg";
import Telefon from "../assets/img/telefon.svg";
import MenuIcon from "../assets/img/menuIcon.svg";
import AvailabilityPanel from "./brandingV2/AvailabilityPanel";


const HeaderInner = styled.header`
  width: calc(100%);
  margin: 0 auto;
  background: none;
  z-index: 10;
  background: ${props => props.schovatBorderBot ? "rgb(255,238,98)" : "none"};
  background: ${props => props.schovatBorderBot ? "#f3efde" : "none"};

  @media (min-width: 900px) {
    border-bottom: none;
  }

  .hlavniMenu {
    padding: 20px 0;
    height: 24px;
    border-bottom: ${props => props.schovatBorderBot ? "none" : "1px solid #DEDEDE"};
    border-bottom: ${props => props.submenu ? "1px solid #DEDEDE !important" : "none"};

    @media (min-width: 900px) {
      padding: ${props => props.submenu ? "20px 0" : "30px 0"};
    }
  }

  .headerWrapper {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
  }

  .logo {
    display: inline-block;
    vertical-align: middle;
    position: absolute;
    top: 0px;
    left: 25px;

    img {
      height: 28px;
    }

    @media (min-width: 900px) {
      top: 0;
    }
  }

  .desktopHeader {
    display: none;
    position: absolute;
    top: 3px;
    right: 25px;
    width: calc(100% - 50px - 160px);

    nav {
      display: inline-block;

      ul {
        li {
          list-style: none;
          display: inline-block;
          margin-right: 30px;
          padding-bottom: 18px;

          a {
            font-family: Visuelt-Regular, Helvetica;
            font-size: 16px;
            color: #000000;
            text-decoration: none;
          }

          button {
            padding: 0;
            background: none;
            font-family: Visuelt-Regular, Helvetica;
            font-size: 16px;
            color: #000000;
            text-decoration: none;
            border: 0;
            cursor: pointer;
          }

          &:hover {
            border-bottom: ${props => props.submenu ? "3px solid #DFDFDF" : "3px solid rgba(0,0,0,0.2)"};
            margin-bottom: -3px;
          }
        }
      }

      .typyPohrbu {
        display: none;
        position: absolute;
        z-index: 1;
        bottom: -80px;
        background: white;
        box-shadow: 0 2px 20px 0 rgb(0 0 0 / 15%);
        padding: 3px 30px;
        border-radius: 5px;

        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 50px;
          width: 0;
          height: 0;
          border: 10px solid transparent;
          border-bottom-color: #ffffff;
          border-top: 0;
          margin-left: -10px;
          margin-top: -10px;
        }

        li {
          padding-top: 18px;

          &:last-child {
            margin-right: 0;
          }

          &:hover {
            border-bottom: ${props => props.submenu ? "4px solid #DFDFDF" : "3px solid rgba(0,0,0,0.2)"};
            margin-bottom: -4px;
          }
        }
      }

      .typyActive {
        display: block;
      }


    }

    div {
      display: inline-block;
      position: absolute;
      top: 0;
      right: 0;

      img {
        height: 22px;
        vertical-align: middle;
        margin-right: 15px;
        position: relative;
        top: -1px;

        @media (max-width: 950px) {
          display: none
        }
      }

      a {
        vertical-align: middle;
        font-family: Visuelt-Bold, Helvetica;
        font-size: 16px;
        color: #000000;

        span {
          font-family: Visuelt-Regular, Helvetica;
          font-size: 16px;
          color: #000000;
        }
      }
    }

    @media (min-width: 900px) {
      display: block;
    }
  }

  .mobileHeader {
    display: inline-block;
    vertical-align: middle;
    position: absolute;
    top: 1px;
    right: 25px;

    img {
      width: 25px;
    }

    @media (min-width: 900px) {
      display: none;
    }
  }

  .submenu {
    display: none;
    border-bottom: 1px solid #DEDEDE !important;
    width: 100%;
    height: 64px;
    margin: 0 auto;
    position: relative;
    background: #FFFFFF;
    max-width: 1200px;

    @media (min-width: 900px) {
      display: ${props => props.submenu ? "block" : "none"};
    }

    @media (min-width: 1200px) {
      max-width: 1150px;
    }

    nav {
      max-width: 1150px;
      width: calc(100% - 50px);
      margin: 0 auto;
      position: relative;

      @media (min-width: 1200px) {
        width: calc(100%);
      }

      .button {
        display: block;
        position: absolute;
        right: 0;
        top: 17px;
        background: #566FEF;
        padding: 5px 0;
        font-family: Visuelt-Medium;
        font-size: 16px;
        color: #FFFFFF;
        text-align: center;
        text-decoration: none;
        border-radius: 5px;
        width: 148px;

        &:hover {
          background: #485ECA;
        }
      }

      ul {
        padding-top: 22px;

        li {
          display: inline-block;
          margin-right: 50px;

          a {
            font-family: Visuelt-Medium;
            font-size: 16px;
            color: #000000;
            text-decoration: none;

            &:hover {
              border-bottom: 4px solid #DFDFDF;
              padding-bottom: 19px;
            }
          }
        }
      }
    }
  }

  .submenuPohrby {
    max-width: none;


    nav {
      .button {
        display: block;
        position: absolute;
        right: 0;
        top: 15px;
        background: #243A58;
        padding: 7px 0;
        font-family: Visuelt-Medium;
        font-size: 16px;
        color: #FFFFFF;
        text-align: center;
        text-decoration: none;
        border-radius: 3px;
        width: 120px;

        &:hover {
          background: #1a2b42;
        }
      }
    }
  }

  .cta {
    display: flex !important;
    align-items: center !important;
    position: absolute !important;
    top: -8px !important;
    right: 0;

    img {
     height: auto !important;
     position: static !important;
     margin: 0 !important;

     @media (max-width: 950px) {
        display: block !important;
      }
    }

    a {
      text-decoration: none !important;
      width: fit-content;
    }

    span {
      text-decoration: none !important;
      font-family: Visuelt-Medium !important;
      font-size: 16px !important;
    }

    .login {
      border: 2px solid #95A8C3;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 5px;
      padding: 5px 10px;
      margin-right: 15px;

      @media (max-width: 935px) {
        display: none
      }

      span {
        color: #394B5C;
        margin-left: 8px;

        @media (max-width: 1030px) {
          display: none
        }
      }

      img {
        height: 19px !important;
      }

      &:hover {
        background: #E2EBF8;
        cursor: pointer;
      }
    }

    .quote {
      padding: 7px 12px;
      background: #3b557c;
      border-radius: 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        color: #FFFFFF;
        margin-right: 8px;
      }

      &:hover {
        background: #2A4164;
        cursor: pointer;
      }
    }
  }
`;

const activeStyles = {
  "borderBottom": "3px solid rgba(0,0,0,0.2)",
  "paddingBottom": "5px"
}

const activeStyles2Top = {
  "borderBottom": "3px solid #DFDFDF",
  "paddingBottom": "18px"
}

const activeStyles2Bot = {
  "borderBottom": "4px solid #1D3A5B",
  "paddingBottom": "19px"
}

const activeStyles2BotPohreb = {
  "borderBottom": "4px solid #1D3A5B",
  "paddingBottom": "19px"
}



const Header = ({ otevritMenu, noSubmenu, V2 }) => {
  const location = useLocation();

  const [typyVyber, setTypyVyber] = useState(false);

  const togglTypyVyber = () => {
    if (typyVyber === true) {
      setTypyVyber(false);
    }

    else  {
      setTypyVyber(true);
    }
  }


  // jsou to pohrebniSluzby?

  let isTypyPohrbu = false;

  if (location.pathname.startsWith("/kremace-bez-obradu") || location.pathname.startsWith("/pohreb-s-obradem") || location.pathname.startsWith("/netradicni-pohreb")) {
    isTypyPohrbu = true;
  }




  // spodní border

  let schovatBorderBot = false;

  // if (location.pathname.startsWith("/o-nas")) {
  //   schovatBorderBot = true;
  // }


  // submenu

  let submenu = true;


  // if (location.pathname.startsWith("/o-nas")) {
  //   submenu = false;
  // }

  return (
    <>
      {V2 && <AvailabilityPanel />}

      <HeaderInner schovatBorderBot={schovatBorderBot} submenu={submenu}>
        <div className="hlavniMenu">
          <div className="headerWrapper">
          <Link className="logo" to="/">
            <img loading="eager" src={Logo} alt="Logo Goodbye" />
          </Link>

          <div className="desktopHeader">
            <nav>
              <ul>
                <li>
                  <button
                    className={(!isTypyPohrbu) ? "" : "nedostupny"}
                    onClick={() => !isTypyPohrbu ? togglTypyVyber() : null}
                    style={(isTypyPohrbu) ? activeStyles2Top : null}
                  >Varianty pohřbů</button>
                </li>
                <li><Link style={(location.pathname.startsWith("/zaveti")) ? activeStyles2Top : null} to="/zaveti/">Závěti</Link></li>
                <li><Link style={(location.pathname.startsWith("/parte")) ? activeStyles2Top : null} to="/parte/">Parte</Link></li>
                <li><Link style={(location.pathname.startsWith("/poradna")) ? activeStyles2Top : null} to="/poradna/">Poradna</Link></li>
                <li><Link style={(location.pathname.startsWith("/care")) ? activeStyles2Top : null} to="/care/">Care</Link></li>
                <li><Link activeStyle={(submenu) ? activeStyles2Top : activeStyles} to="/o-nas/">O nás</Link></li>
              </ul>

              <ul className={"typyPohrbu" + (typyVyber ? " typyActive" : "")}>
                <li><Link style={(location.pathname.startsWith("/kremace-bez-obradu")) ? activeStyles2BotPohreb : null} to="/kremace-bez-obradu/">Bez obřadu</Link></li>
                <li><Link style={(location.pathname.startsWith("/pohreb-s-obradem")) ? activeStyles2BotPohreb : null} to="/pohreb-s-obradem/">S obřadem</Link></li>
                <li><Link style={(location.pathname.startsWith("/netradicni-pohreb")) ? activeStyles2BotPohreb : null} to="/netradicni-pohreb/">Netradiční</Link></li>
                <li><Link style={(location.pathname.startsWith("/predplaceny-pohreb")) ? activeStyles2BotPohreb : null} to="/predplaceny-pohreb/">Předplacený</Link></li>
                <li><Link style={(location.pathname.startsWith("/pohreb-miminka")) ? activeStyles2BotPohreb : null} to="/pohreb-miminka/">Pohřeb miminka</Link></li>
              </ul>
            </nav>

            {!V2 &&
              <div>
                <img src={Telefon} alt="Pomocná linka" />
                <a href="tel:315558136">315 558 136<span> - Pomocná linka</span></a>
              </div>
            }

            {V2 && 
              <div className="cta">
                {(location.pathname.startsWith("/zaveti")) ? 
                <>
                  <Link className="login" to="https://zaveti.goodbye.cz/prihlaseni">
                    <img src={loginImg} alt="" />
                    <span>Přihlásit se</span>
                  </Link>

                  <Link className="quote" to="https://zaveti.goodbye.cz/registrace">
                    <span>Zkusit ZDARMA</span>
                    <img src={arrowImg} alt="" />
                  </Link>
                </>
                :
                <>
                  {(location.pathname.startsWith("/predplaceny-pohreb")) ? <>
                    <Link className="login" to="/prihlasit-se/">
                      <img src={loginImg} alt="" />
                      <span>Přihlásit se</span>
                    </Link>

                    <Link className="quote" to="/predplaceny-pohreb/formular/">
                      <span>Nabídka ZDARMA</span>
                      <img src={arrowImg} alt="" />
                    </Link>
                  </>:<>
                    <Link className="login" to="/prihlasit-se/">
                      <img src={loginImg} alt="" />
                      <span>Přihlásit se</span>
                    </Link>

                    <Link className="quote" to="/nabidka/dotaznik/">
                      <span>Nabídka ZDARMA</span>
                      <img src={arrowImg} alt="" />
                    </Link>
                  </>}
                </>
                }
                
              </div>
            }

          </div>

          <div onClick={() => otevritMenu()} onKeyDown={() => otevritMenu()} className="mobileHeader" role="button" tabIndex={0}>
            <img src={MenuIcon} alt="Menu" />
          </div>
        </div>
        </div>


        {(isTypyPohrbu) ?
          <div className="submenu submenuPohrby">
            <nav>
              <ul>
                <li><Link style={(location.pathname.startsWith("/kremace-bez-obradu")) ? activeStyles2BotPohreb : null} to="/kremace-bez-obradu/">Bez obřadu</Link></li>
                <li><Link style={(location.pathname.startsWith("/pohreb-s-obradem")) ? activeStyles2BotPohreb : null} to="/pohreb-s-obradem/">S obřadem</Link></li>
                <li><Link style={(location.pathname.startsWith("/netradicni-pohreb")) ? activeStyles2BotPohreb : null} to="/netradicni-pohreb/">Netradiční</Link></li>
                <li><Link style={(location.pathname.startsWith("/predplaceny-pohreb")) ? activeStyles2BotPohreb : null} to="/predplaceny-pohreb/">Předplacený pohřeb</Link></li>
                <li><Link style={(location.pathname.startsWith("/pohreb-miminka")) ? activeStyles2BotPohreb : null} to="/pohreb-miminka/">Pohřeb miminka</Link></li>
              </ul>
              <Link className="button" to="/prihlasit-se/">Přihlásit se</Link>
            </nav>
          </div>
        : null}

      </HeaderInner>
    </>
    
  )
}


export default Header;
